export default {
  public: [],
  admin: [
    // 工程實績列表
    {
      path: 'project',
      name: 'project',
      component: () => import('@/modules/project/views/projectList.vue'),
    },

    // 工程實績表單
    {
      path: 'project/create',
      name: 'project-create',
      meta: {
        formMode: 'create',
      },
      component: () => import('@/modules/project/views/projectForm.vue'),
    },
    {
      path: 'project/update/:target',
      name: 'project-update',
      meta: {
        formMode: 'update',
      },
      component: () => import('@/modules/project/views/projectForm.vue'),
    },

    // 計劃書列表
    {
      path: 'plan',
      name: 'plan',
      component: () => import('@/modules/project/views/planList.vue'),
    },

    // 計劃書表單
    {
      path: 'plan/create',
      name: 'plan-create',
      meta: {
        formMode: 'create',
      },
      component: () => import('@/modules/project/views/planForm.vue'),
    },
    {
      path: 'plan/update/:target',
      name: 'plan-update',
      meta: {
        formMode: 'update',
      },
      component: () => import('@/modules/project/views/planForm.vue'),
    },
  ],
}
